/* eslint-disable */
import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userRole = userData && userData.role ? userData.role : null

      if (userData !== null && userRole !== null) return { name: 'dashboard' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/logout',
    name: 'auth-logout',
    component: () => {},
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/administrators',
    name: 'administrators',
    component: () => import('@/views/pages/Administrators.vue'),
    meta: {
      layout: 'content',
      resource: 'Administrators',
      action: 'viewAny',
    },
  },
  {
    path: '/partner-api-keys',
    name: 'partner-api-keys',
    component: () => import('@/views/pages/PartnerApiKeys.vue'),
    meta: {
      layout: 'content',
      resource: 'PartnerApiKey',
      action: 'viewAny',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/Dashboard.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/donte',
    name: 'donte',
    component: () => import('@/views/pages/Donte.vue'),
    meta: {
      layout: 'content',
      resource: 'Donte',
      action: 'viewAny',
    },
  },
  {
    path: '/donte-reports',
    name: 'donteReports',
    component: () => import('@/views/pages/DonteReports.vue'),
    meta: {
      layout: 'content',
      resource: 'DonteReports',
      action: 'viewAny',
    },
  },
  {
    path: '/donte-reports/report/:id',
    name: 'donteReport',
    component: () => import('@/views/components/donte/DonteReport.vue'),
    meta: {
      layout: 'content',
      resource: 'Donte',
      action: 'view',
    },
  },
  {
    path: '/meer',
    name: 'meer',
    component: () => import('@/views/pages/Meer.vue'),
    meta: {
      layout: 'content',
      resource: 'Meer',
      action: 'viewAny',
    },
  },
  {
    path: '/picolus',
    name: 'picolus',
    component: () => import('@/views/pages/Picolus.vue'),
    meta: {
      layout: 'content',
      resource: 'Picolus',
      action: 'viewAny',
    },
  },
  {
    path: '/teddy',
    name: 'teddy',
    component: () => import('@/views/pages/Teddy.vue'),
    meta: {
      layout: 'content',
      resource: 'Teddy',
      action: 'viewAny',
    },
  },
  {
    path: '/votmax',
    name: 'votmax',
    component: () => import('@/views/pages/Votmax.vue'),
    meta: {
      layout: 'content',
      resource: 'Votmax',
      action: 'viewAny',
    },
  },
  {
    path: '/zanty',
    name: 'zanty',
    component: () => import('@/views/pages/Zanty.vue'),
    meta: {
      layout: 'content',
      resource: 'Zanty',
      action: 'viewAny',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach(async (to, _, next) => {
  const userData = localStorage.getItem('userData')
  const usersAbilities = JSON.parse(localStorage.getItem('userAbility'))
  const isLoggedIn = Boolean(userData && localStorage.getItem('accessToken') && usersAbilities)

  if(to.name === 'misc-not-authorized') {
    return next()
  }


  if(to.name === 'auth-logout') {
    return await router.app.$userService.logout(true)
  }


  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }


  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn)
    return next('/')


  return next()
})

export default router
