import { mdiAccountSupervisorOutline, mdiKeyChainVariant } from '@mdi/js'

export default [
  {
    title: 'Administrators',
    to: 'administrators',
    icon: mdiAccountSupervisorOutline,
    resource: 'Administrators',
    action: 'viewAny',
  },
  {
    title: 'Partner Api Keys',
    to: 'partner-api-keys',
    icon: mdiKeyChainVariant,
    resource: 'PartnerApiKey',
    action: 'viewAny',
  },
]
