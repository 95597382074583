export default {
  key: 'endpointService',
  install(app) {
    // eslint-disable-next-line no-param-reassign
    app.prototype.$endpointService = {
      api: process.env.API_URL,
      docs: process.env.DOCS_URL,
    }
  },
}

export const _ = null
