import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { setContext } from '@apollo/client/link/context'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'

Vue.use(VueApollo)

export default {
  install(app) {
    const vm = app.prototype

    const apiUrl = process.env.API_URL.replace(/\/$/, '')

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = localStorage.getItem('accessToken')

      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      }
    })

    const httpLink = createHttpLink({
      // You should use an absolute URL here
      uri: `${apiUrl}/graphql`,
      credentials: 'same-origin',
    })

    const cache = new InMemoryCache()

    const apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache,
    })

    const apolloProvider = new VueApollo({
      defaultClient: apolloClient,
      errorHandler(error) {
        if (error.message === 'GraphQL error: Unauthenticated.') {
          //   router.app.$toastr.e('Unauthenticated')
          //   //logout
          //   localStorage.removeItem('accessToken')
          //   localStorage.removeItem('userData')
          //   localStorage.removeItem('userRole')
          //   localStorage.removeItem('userAbility')
          //   router.app.$ability.update(initialAbility)

          //   // Remove apollo cache
          //   router.app.$apolloProvider.defaultClient.cache.data.clear()
          //   // Redirect to login page
          //   router.push({ name: 'auth-login' })

          return vm.$userService.logout(true)
        }

        return vm.$toastr.e(error.networkError ? 'Network Error' : 'Server Error')
      },
    })

    // eslint-disable-next-line no-param-reassign
    app.prototype.$apolloProvider = apolloProvider
  },
}
