/* eslint-disable no-unreachable, no-underscore-dangle */
import { ref } from '@vue/composition-api'
import { initialAbility } from '@/plugins/acl/config'
import router from '@/router'

export default {
  key: 'userService',
  install(app) {
    const vm = app.prototype

    const user = ref(null)

    const isCurrentUser = u => {
      if (u?.id == null) return false
      if (user.value?.id == null) return false

      return String(user.value.id) === String(u.id)
    }

    const resolveAbilities = abilities => Array.from(
      abilities.map(p => {
        const [subject, action] = p !== '*' ? p.split(':') : ['all', 'manage']

        return {
          subject,
          action,
        }
      }),
    ).concat([...initialAbility])

    const clearStorage = () => {
      // app.$apolloProvider.defaultClient.resetStore()
      // app.$apolloProvider.defaultClient.cache.data.clear()
      vm.$apolloProvider.defaultClient.clearStore()

      localStorage.removeItem('userData')
      localStorage.removeItem('userRole')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userAbility')

      vm.$ability.update(initialAbility)
    }

    const fillStorage = data => {
      if (data.token) localStorage.setItem('accessToken', data.token)

      if (data.currentUser) {
        localStorage.setItem('userId', data.currentUser.id)
        localStorage.setItem('userRole', data.currentUser.role)
        localStorage.setItem('userData', JSON.stringify(data.currentUser))

        const permissions = resolveAbilities(data.currentUser.abilities) || []

        localStorage.setItem('userAbility', JSON.stringify(permissions))
        vm.$ability.update(permissions)

        user.value = { ...data.currentUser, abilities: permissions }
      }
    }

    // eslint-disable-next-line no-unused-vars
    const logout = (shouldRedirect = false) => {
      clearStorage()
      user.value = {}

      if (shouldRedirect && router.currentRoute.name !== 'auth-login') {
        router.push({ name: 'auth-login' })
      }
    }

    const refresh = () =>
      vm.$http
        .get('/role')
        .then(res => fillStorage(res.data))
        .catch(() => logout(true))

    const init = () => {
      try {
        user.value = JSON.parse(localStorage.getItem('userData'))

        if (localStorage.getItem('userAbility') !== null) {
          user.value = {
            ...user.value,
            abilities: JSON.parse(localStorage.getItem('userAbility')),
          }
        }

        if (user.value) refresh()

        if (user.value) vm.$http.get('/user')
      } catch (e) {
        user.value = null
      }
    }

    // eslint-disable-next-line no-param-reassign
    app.prototype.$userService = {
      init,
      user,
      isCurrentUser,

      resolveAbilities,
      clearStorage,
      fillStorage,
      refresh,
      logout,
    }
  },
}

export const _ = null
