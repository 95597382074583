import { mdiAccountSupervisorOutline } from '@mdi/js'

export default [
  {
    subheader: 'SUBHEADER',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Administrators',
    to: 'administrators',
    icon: mdiAccountSupervisorOutline,
    resource: 'Administrators',
    action: 'viewAny',
  },
]
