/* eslint-disable */
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import { i18n } from '@/plugins/i18n'
import './plugins/acl'
import '@/styles/styles.scss'
import App from './App.vue'

import vuetify from './plugins/vuetify'
import apollo from './plugins/apollo'
import moment from './plugins/moment'

import router from './router'
import store from './store'

import VueToastr from 'vue-toastr'
import Vuelidate from 'vuelidate'
import services from '@/services'

import axios from '@/plugins/axios'

import 'leaflet/dist/leaflet.css'

Object.entries(services)
  .forEach(({ 1: service }) => Vue.use(service))

Vue.use(VueCompositionAPI)

Vue.use(VueToastr, {
  defaultProgressBar: false,
  defaultPosition: 'toast-bottom-right',
})

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(apollo)
Vue.use(moment)

Vue.use(axios)


if (process.env.NODE_ENV !== 'production')
  console.log('Environment:', process.env)


new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  created() {
    this.$userService.init()
  }
}).$mount('#app')
