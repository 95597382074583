/* eslint-disable */
import axios from 'axios'
// import Vue from 'vue'
// import router from '../router'
// import { initialAbility } from '@/plugins/acl/config'


export default {
  install(app) {
    const axiosIns = axios.create({
      // You can add your headers here
      // ================================

      baseURL: `${process.env.API_URL.replace(/\/$/, '')}`,

      // timeout: 1000,
      // headers: {'X-Custom-Header': 'foobar'}
    })

    axiosIns.interceptors.request.use(
      config => {
        // Do something before request is sent
        const accessToken = localStorage.getItem('accessToken')

        // eslint-disable-next-line no-param-reassign
        if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

        return config
      },
      error => Promise.reject(error),
    )

    // Add a response interceptor
    axiosIns.interceptors.response.use(
      function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
      },
      function(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response.status === 401) {
          app.prototype.$userService.logout(true)

          // router.app.$toastr.e('Unauthorized')
          // localStorage.removeItem('accessToken')
          // localStorage.removeItem('userData')
          // localStorage.removeItem('userAbility')
          // localStorage.removeItem('userRole')

          // Reset ability
          // router.app.$ability.update(initialAbility)

          // Remove apollo cache
          // router.app.$apolloProvider.defaultClient.cache.data.clear()
          // Redirect to login page
          // if (router.currentRoute.fullPath !== '/login') router.push({ name: 'auth-login' })
        }



        if (error.response.status === 403) {
          app.prototype.$toastr.e(error.response.message || 'Unauthorized!')

          app.prototype.$userService.refresh()
        }



        // Do something with response error
        return Promise.reject(error)
      },
    )

    app.prototype.$http = axiosIns
  }
}
