<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <go-top
      bg-color="#FFD742"
      :right="30"
      :size="35"
    ></go-top>
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </component>
</template>

<script>
/* eslint-disable */
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'

import GoTop from '@inotom/vue-go-top'

export default {
  components: {
    LayoutContentHorizontalNav,
    LayoutBlank,
    LayoutContentVerticalNav,
    GoTop,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
