import {
  mdiAlphaDBoxOutline,
  mdiAlphaMBoxOutline,
  mdiAlphaPBoxOutline,
  mdiAlphaTBoxOutline,
  mdiAlphaVBoxOutline,
  mdiAlphaZBoxOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'APPS',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Donte',
    to: 'donte',
    icon: mdiAlphaDBoxOutline,
    resource: 'Donte',
    action: 'viewAny',
  },
  {
    title: 'DonteReports',
    to: 'donteReports',
    icon: mdiAlphaDBoxOutline,
    resource: 'DonteReports',
    action: 'viewAny',
  },
  {
    title: 'Meer',
    to: 'meer',
    icon: mdiAlphaMBoxOutline,
    resource: 'Meer',
    action: 'viewAny',
  },
  {
    title: 'Picolus',
    to: 'picolus',
    icon: mdiAlphaPBoxOutline,
    resource: 'Picolus',
    action: 'viewAny',
  },
  {
    title: 'Teddy',
    to: 'teddy',
    icon: mdiAlphaTBoxOutline,
    resource: 'Teddy',
    action: 'viewAny',
  },
  {
    title: 'Votmax',
    to: 'votmax',
    icon: mdiAlphaVBoxOutline,
    resource: 'Votmax',
    action: 'viewAny',
  },
  {
    title: 'Zanty',
    to: 'zanty',
    icon: mdiAlphaZBoxOutline,
    resource: 'Zanty',
    action: 'viewAny',
  },
]
