import dashboard from './dashboard'
import administrators from './administrators'
import apps from './apps'

// Array of sections
export default [
  ...dashboard,
  ...administrators,
  ...apps,
]
