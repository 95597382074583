<template>
  <div>
    <component
      :is="linkComponent"
      :item="{ title: 'Dashboard', icon: mdiHomeOutline, to: 'dashboard' }"
      class="mx-1"
    />

    <vertical-nav-menu-section-title
      v-if="linkComponent === 'VerticalNavMenuLink'"
      :item="{ subheader: 'SUBHEADER'}"
    />
    <component
      :is="linkComponent"
      v-if="can(['Administrators:viewAny'])"
      :item="{ title: 'Administrators', icon: mdiAccountSupervisorOutline, to: 'administrators' }"
      class="mx-1"
    />

    <vertical-nav-menu-section-title
      v-if="linkComponent === 'VerticalNavMenuLink'"
      :item="{ subheader: 'APPS'}"
    />
    <component
      :is="linkComponent"
      v-if="can(['Donte:viewAny'])"
      :item="{ title: 'Donte', icon: mdiAlphaDBoxOutline , to: 'donte' }"
      class="mx-1"
    />
    <component
      :is="linkComponent"
      v-if="can(['DonteReports:viewAny'])"
      :item="{ title: 'Donte Reports', icon: mdiAlphaDBoxOutline, to: 'donteReports' }"
      class="mx-1"
    />
    <component
      :is="linkComponent"
      v-if="can(['Meer:viewAny'])"
      :item="{ title: 'Meer', icon: mdiAlphaMBoxOutline , to: 'meer' }"
      class="mx-1"
    />
    <component
      :is="linkComponent"
      v-if="can(['Picolus:viewAny'])"
      :item="{ title: 'Picolus', icon: mdiAlphaPBoxOutline , to: 'picolus' }"
      class="mx-1"
    />
    <component
      :is="linkComponent"
      v-if="can(['Teddy:viewAny'])"
      :item="{ title: 'Teddy', icon: mdiAlphaTBoxOutline , to: 'teddy' }"
      class="mx-1"
    />
    <component
      :is="linkComponent"
      v-if="can(['Votmax:viewAny'])"
      :item="{ title: 'Votmax', icon: mdiAlphaVBoxOutline , to: 'votmax' }"
      class="mx-1"
    />
    <component
      :is="linkComponent"
      v-if="can(['Zanty:viewAny'])"
      :item="{ title: 'Zanty', icon: mdiAlphaZBoxOutline , to: 'zanty' }"
      class="mx-1"
    />
  </div>
</template>

<script>
import {
  mdiAccountSupervisorOutline,
  mdiViewListOutline,
  mdiChartBoxOutline,
  mdiHomeOutline,
  mdiAlphaDBoxOutline,
  mdiAlphaZBoxOutline,
  mdiAlphaPBoxOutline,
  mdiAlphaVBoxOutline,
  mdiAlphaMBoxOutline,
  mdiAlphaTBoxOutline,
} from '@mdi/js'
import HorizontalNavMenuHeaderLink from '@core/layouts/components/horizontal-nav-menu/components/horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import VerticalNavMenuLink from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuSectionTitle from '@/@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue'
// import AbilityMixin from '@/mixins/AbilityMixin'

export default {
  name: 'NavLinks',

  components: {
    HorizontalNavMenuHeaderLink,
    VerticalNavMenuLink,
    VerticalNavMenuSectionTitle,
  },

  // mixins: [AbilityMixin],

  props: {
    linkComponent: {
      required: true,
      type: String,
    },
  },

  setup() {
    return {
      mdiAccountSupervisorOutline,
      mdiViewListOutline,
      mdiChartBoxOutline,
      mdiHomeOutline,
      mdiAlphaDBoxOutline,
      mdiAlphaZBoxOutline,
      mdiAlphaPBoxOutline,
      mdiAlphaVBoxOutline,
      mdiAlphaMBoxOutline,
      mdiAlphaTBoxOutline,
    }
  },
}
</script>
